import { UAParser } from 'ua-parser-js';

const deviceStats = {
    getScreenStats: function () {
        const ratio = window.screen.width / window.screen.height
        return {
            w: window.screen.width,
            h: window.screen.height,
            resolution: `${window.screen.width}x${window.screen.height}`,
            ratio,
            orientation: ratio === 1 ? 'square' : (ratio > 1 ? 'landscape' : 'portait')
        }
    },
    getStorage: async function () {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            const { usage, quota } = await navigator.storage.estimate()
            return { usage, quota }
        }
        return { usage: 'Unknown', quota: 'Unknown' }
    },
    getDeviceData: function () {
        const uap = new UAParser();
        const result = uap.getResult()
        return {
            vendor: result.device.vendor,
            type: result.device.type,
            model: result.device.model,
            cpu: result.cpu.architecture,
            browserName: result.browser.name,
            browserVersion: result.browser.version,
            browserMajorVersion: result.browser.major,
            browserEngineName: result.engine.name,
            browserEngineVersion: result.engine.version,
            osName: result.os.name,
            osVersion: result.os.version,
            userAgent: result.ua,
            language: navigator.language
        }
    },
    getDeviceLocationData: async function () {
        const location = await fetch('https://ipapi.co/json/').then(res => res.json())
        return location
    }

}

export default deviceStats